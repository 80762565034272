<template>
    <div>
        <MenuGlobalLoader :loading="loading">
            <template #loader>
                <svg viewBox="25 25 50 50">
                    <circle
                        class="loader-path"
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        stroke="#636363"
                        stroke-width="2"
                    />
                </svg>
            </template>
            <template #logo>
                <img
                    class="mx-auto"
                    src="@/assets/images/logo-cleaned.png"
                    alt="Hydraclic logo"
                >
            </template>
        </MenuGlobalLoader>

        <slot />
        <Toaster ref="toaster" />
    </div>
</template>
<script lang="ts">
import { useConfigStore } from '~/store'
export default {
    name : "EmptyLayout",
    data: () => ({
    }),
    computed : {
        loading () {
            return useConfigStore().globalLoading
        }
    },
    mounted () {
        useConfigStore().setGlobalLoading(false)
    },
}
</script>
